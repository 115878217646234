import { Link as GatsbyLink } from "gatsby";
import React, { Component } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { injectIntl, Link } from "gatsby-plugin-intl";
import SVG from "react-inlinesvg";

import { cn } from "../lib/helpers";
import styles from "./header.module.css";

import GrafiaLogo from "../images/grafia.svg";
import Logo from "src/images/huiput-final.svg";
import MenuBurger from "src/images/menu-burger.svg";
import MenuClose from "src/images/menu-close.svg";

const StyledHeader = styled.div`
  position: relative;
  padding: 25px;
  color: var(--color-white);
  font-size: 20px;

  a:hover {
    color: inherit;
  }

  ${breakpoint("lg")`
    padding: 25px calc(5vw + 10px);
    padding: 25px calc(5vw + 10px);
  `}
`;

const StyledHeaderContainer = styled.div`
  ${breakpoint("lg")`
    display: flex;
    display: flex;
    justify-content: space-between;
    height: 56px;
  `}
`;

const StyledLogo = styled.div`
  width: 170px;
  height: 56px;

  a {
    display: block;
    height: 56px;
    color: var(--color-white);
  }

  img {
    width: 160px;
    height: auto;
  }

  ${breakpoint("md")`
    display: initial;
  `}
`;

const StyledMenuBurger = styled.button`
  position: absolute;
  top: 25px;
  right: 25px;
`;

const StyledMenuClose = styled.button`
  position: absolute;
  top: 25px;
  right: 27.5px;
  z-index: 999;
`;

const StyledNavigation = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  text-align: center;
`;

const StyledNavigationContent = styled.div`
  z-index: 998;
  background-color: var(--color-pink);
  color: var(--color-white);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: inherit;
  min-height: 680px;
  height: 100vh;
  width: 100%;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledLanguage = styled.ul`
  list-style: none;
  position: absolute;
  top: 20px;
  left: 25px;
  display: inline-block;
  z-index: 999;
`;

const StyledLanguageItem = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  color: var(--color-white);
  font-size: 20px;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const StyledMenuLogo = styled.div`
  padding: 15vh 0 10px;
  color: var(--color-white);

  img,
  svg {
    width: 100%;
    max-width: 200px;
    height: auto;
  }
`;

const StyledMenu = styled.ul`
  list-style: none;
  margin: 0;
  padding: 10px 0;
`;

const StyledMenuItem = styled.li`
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: var(--font-family-bold);
  font-size: 30px;
  letter-spacing: 0.06em;
`;

// const StyledLocation = styled.p`
//   margin: 0;
//   padding: 10px 0;
//   text-transform: uppercase;
//   letter-spacing: 0.06em;
// `;

const StyledHashtag = styled.div`
  padding: 10px 0 15vh;
  font-family: var(--font-family-mono);
`;

const StyledDesktopMenu = styled.nav`
  display: none;

  ${breakpoint("lg")`
    display: flex;
    display: flex;
    flex-grow: 1;
    justify-content: center;
    margin: 0 50px;
  `}
`;

const StyledDesktopMenuInner = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  align-self: center;

  ${breakpoint("lg")`
    color: var(--color-black);
    color: var(--color-black);

    a {
      color: inherit;
    }
  `}
`;

const StyledDesktopMenuItem = styled.li`
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  text-transform: uppercase;
  font-family: var(--font-family-mono);

  ${breakpoint("lg")`
    font-size: 20px;
    font-size: 20px;
    padding: 0 12px 0 12px;

    a {
      margin-top: 3px;
      align-self: center;
    }
  `}
`;

const StyledDesktopGrafia = styled.div`
  display: none;
  display: none;

  svg {
    display: block;
    width: 80px;
    height: auto;
    margin: 0 auto;
  }

  a {
    color: inherit;
  }

  ${breakpoint("xl")`
    display: initial;
    display: initial;
    color: var(--color-black);
    align-self: center;
    padding-right: 12px;
  `}
`;

const StyledDesktopLanguage = styled.nav`
  display: none;

  ${breakpoint("lg")`
    display: flex;
    display: flex;
    margin-left: auto;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    color: var(--color-black);
  `}

  ${breakpoint("xl")`
    margin: 0 50px 0 0;
    margin: 0 50px 0 0;
  `}
`;

const StyledDesktopLanguageInner = styled.ul`
  display: flex;
  align-items: center;
  text-align: right;
  list-style: none;
  height: inherit;
  margin: 0 auto;
  padding: 0 4px 0 4px;
`;

const StyledDesktopLanguageItem = styled.li`
  display: inline-block;
  padding: 0;
  margin: 0;
  color: var(--color-black);
  align-self: center;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

const Header = ({ intl, onHideNav, onShowNav, showNav, siteTitle }) => {
  const closeMenu = (e) => {
    const button = document.querySelector(".menu-close");

    if (button) {
      button.click();
    }
  }

  return (
    <StyledHeader>
      <StyledHeaderContainer>
        <StyledLogo>
          <Link to="/">
            <img src={Logo} alt={siteTitle} />
          </Link>
        </StyledLogo>
        <StyledDesktopMenu>
          <MenuComponent
            intl={intl}
            component={StyledDesktopMenuInner}
            itemComponent={StyledDesktopMenuItem}
            class="link"
          />
        </StyledDesktopMenu>
        <StyledDesktopLanguage>
          <StyledDesktopLanguageInner>
            <StyledDesktopLanguageItem>
              <GatsbyLink to="/en/" className="en">
                EN
            </GatsbyLink>
              &nbsp;/&nbsp;
          </StyledDesktopLanguageItem>
            <StyledDesktopLanguageItem>
              <GatsbyLink to="/fi/" className="fi">
                FI
            </GatsbyLink>
            </StyledDesktopLanguageItem>
          </StyledDesktopLanguageInner>
        </StyledDesktopLanguage>
        <StyledDesktopGrafia>
          <a href="https://grafia.fi/" title="Grafia" target="_blank">
            <SVG src={GrafiaLogo} />
          </a>
        </StyledDesktopGrafia>
        <StyledMenuBurger
          className={styles.toggleNavButton}
          onClick={showNav ? onHideNav : onShowNav}
        >
          <img src={MenuBurger} alt="Open" />
        </StyledMenuBurger>
        <nav className={cn(styles.nav, showNav && styles.showNav)}>
          <StyledMenuClose
            className={cn("menu-close", styles.toggleNavButton)}
            onClick={showNav ? onHideNav : onShowNav}
          >
            <img src={MenuClose} alt="Close" />
          </StyledMenuClose>
          <StyledLanguage>
            <StyledLanguageItem>
              <GatsbyLink to="/en/" className="en">
                EN
              </GatsbyLink>
              &nbsp;/&nbsp;
            </StyledLanguageItem>
            <StyledLanguageItem>
              <GatsbyLink to="/fi/" className="fi">
                FI
              </GatsbyLink>
            </StyledLanguageItem>
          </StyledLanguage>
          <StyledNavigation>
            <StyledNavigationContent>
              <StyledMenuLogo>
                <Link to="/" onClick={(e) => closeMenu(e)}>
                  <SVG src={Logo} />
                </Link>
              </StyledMenuLogo>
              <MenuComponent intl={intl} component={StyledMenu} itemComponent={StyledMenuItem} />
              {/* <StyledLocation>
                {intl.messages.date}
                <br />
                {intl.messages.location_1}
                <br />
                {intl.messages.location_2}
              </StyledLocation> */}
              <StyledHashtag>{intl.messages.hashtag}</StyledHashtag>
            </StyledNavigationContent>
          </StyledNavigation>
        </nav>
      </StyledHeaderContainer>
    </StyledHeader>
  );
};

const MenuComponent = props => {
  const Component = props.component;
  const ItemComponent = props.itemComponent;
  const intl = props.intl;

  const closeMenu = (e) => {
    const button = document.querySelector(".menu-close");

    if (button && window !== "undefined") {
      if (window.getComputedStyle(button).display === "block") {
        button.click();
      }
    }
  }

  return (
    <Component>
      <ItemComponent>
        <Link to="/programme/" className={props.class} onClick={(e) => closeMenu(e)}>
          {intl.messages.menu_programme}
        </Link>
      </ItemComponent>
      <ItemComponent>
        <Link to="/info/" className={props.class} onClick={(e) => closeMenu(e)}>
          {intl.messages.menu_info}
        </Link>
      </ItemComponent>
      <ItemComponent>
        <Link to="/timetable/" className={props.class} onClick={(e) => closeMenu(e)}>
          {intl.messages.menu_schedule}
        </Link>
      </ItemComponent>
      <ItemComponent>
        <a href="https://www.grafia.fi/kauppa/" className={props.class} target="_blank">
          {intl.messages.menu_tickets}
        </a>
      </ItemComponent>
    </Component>
  );
};

export default injectIntl(Header);
