import React from "react";
import Header from "./header";
import styled, { ThemeProvider } from "styled-components";
import breakpoint from "styled-components-breakpoint";
import SizeAware from "../components/size-aware";
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'

import "../styles/layout.css";

import LeftGutterSvg from "../images/gutter-left-3.svg";
import RightGutterSvg from "../images/gutter-right-2.svg";

export const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200
  },
  spacing: {
    xs: 15,
    sm: 30,
    md: 30,
    lg: 40,
    xl: 60
  }
};

const StyledLeftGutter = styled.div`
  padding: 15px 14% 0 24%;
`;

const StyledRightGutter = styled.div`
  padding: 0 0 0 6%;
`;

const StyledPanelContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 250px;
  padding: 0;
  justify-content: space-around;
  flex-wrap: nowrap;
  align-items: stretch;
  overflow: auto;
  height: 100vh;
  height: -webkit-fill-available;

  .content-height {
    height: 100%;
    max-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 2px);
  }

  .content-height-minus {
    height: 100%;
    max-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) - 45px + 2px);
  }

  .content-height-new {
    height: inherit;
  }

  .content-height-min {
    height: 100%;
    min-height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 2px);
  }
`;

const StyledPanelTop = styled.div`
  flex: none;
  background-color: var(--color-beige-v2);
  border-bottom: 2px solid var(--color-black);

  ${breakpoint("xl")`
    overflow: hidden;
    height: 106px;
  `}
`;

const StyledPanelContent = styled.div`
  display: flex;
  position: relative;
  flex: 1 1 auto;
  overflow: hidden;
  height: calc((100vh - ${props => props.headerHeight ? `${props.headerHeight}px` : `0px`}) + 18px);

  body[data-path*="/fi/speaker/"] &,
  body[data-path*="/en/speaker/"] & {
    background-color: var(--color-pink);
  }
`;

const StyledPanelContentBlock = styled.div``;

const StyledPanelContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const StyledPanelContentLeft = styled.div`
  display: none;
  position: relative;
  flex-basis: 15vw;
  border-right: 2px solid var(--color-black);

  ${breakpoint("md")`
    display: unset;
    display: unset;
    max-width: 74px;
  `}

  ${breakpoint("lg")`
    flex-basis: 5vw;
    flex-basis: 5vw;
    min-width: 74px;
  `}

  body[data-path*="/fi/speaker/"] &,
  body[data-path*="/en/speaker/"] & {
    display: none;
  }
`;

const StyledPanelContentLeftSvg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  img {
    width: 90%;
    height: auto;
    margin: 0 auto;
  }
`;

const StyledPanelContentCenter = styled.div`
  ${breakpoint("lg")`
    flex-basis: 100vw;
    flex-basis: 100vw;

    body[data-path*="/fi/speaker/"] &,
    body[data-path*="/en/speaker/"] & {
      flex-basis: unset;
    }
  `}
`;

const StyledPanelContentRight = styled.div`
  display: none;
  position: relative;
  flex-basis: 15vw;
  border-left: 2px solid var(--color-black);

  ${breakpoint("md")`
    display: unset;
    display: unset;
    max-width: 74px;
  `}

  ${breakpoint("lg")`
    flex-basis: 5vw;
    flex-basis: 5vw;
    min-width: 74px;
  `}

  body[data-path*="/fi/speaker/"] &,
  body[data-path*="/en/speaker/"] & {
    display: none;
  }
`;

const StyledPanelContentRightSvg = styled.div`
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  height: 105%;
  overflow: hidden;

  img {
    width: 95%;
    height: auto;
    margin: 0 auto;
  }
`;

export const FixedImage = styled.div`
  display: none;

  ${breakpoint("md")`
    display: block;
    position: fixed;
    z-index: 1;
    bottom: -2px;
    left: 0;
    width: 50%;
    border-right: 2px solid var(--color-black);
    height: calc(100vh - 106px);
    background-image: url(${props => props.fixedImageSrc});
    background-size: cover;
    background-position: 50% 50%;
    background-color: var(--color-black);
    /*
    background-color: var(--color-pink);
    background-blend-mode: screen;
    */
  `}

  ${breakpoint("lg")`
    bottom: -2px;
  `}
`;

const Layout = ({ children, onHideNav, onShowNav, showNav, siteTitle, ...props }) => {
  // console.log(location)
  // console.log(prevLocation)
  return (
    <ThemeProvider theme={theme}>
      <>
        <LayoutPanelsComponent
          siteTitle={siteTitle}
          onHideNav={onHideNav}
          onShowNav={onShowNav}
          showNav={showNav}
          children={children}
          {...props}
        />
      </>
    </ThemeProvider>
  )
};

class LayoutPanelsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      headerHeight: 0
    }
  }

  onSize = size => {
    // console.log('Header has a height of', size.height);
    this.setState({
      headerHeight: size.height
    })
  }

  render() {
    return (
      <StyledPanelContainer headerHeight={this.state.headerHeight}>
        <SizeAware onSize={this.onSize}>
          <StyledPanelTop>
            <Header
              siteTitle={this.props.siteTitle}
              onHideNav={this.props.onHideNav}
              onShowNav={this.props.onShowNav}
              showNav={this.props.showNav}
            />
          </StyledPanelTop>
        </SizeAware>
        <StyledPanelContent headerHeight={this.state.headerHeight} id="scroller">
          <Parallax className="parallax" scrolling={true} vertical ref={ref => (this.parallax = ref)}>
            <StyledPanelContentBlock>
              <StyledPanelContentContainer>
                <StyledPanelContentLeft>
                  <StyledPanelContentLeftSvg>
                    <ParallaxLayer offset={0} speed={0.2} factor={0.5}>
                      <StyledLeftGutter>
                        <img src={LeftGutterSvg} />
                        <img src={LeftGutterSvg} />
                      </StyledLeftGutter>
                    </ParallaxLayer>
                  </StyledPanelContentLeftSvg>
                </StyledPanelContentLeft>
                <StyledPanelContentCenter id="center-scroller">
                  {this.props.children}
                </StyledPanelContentCenter>
                <StyledPanelContentRight>
                  <StyledPanelContentRightSvg>
                    <ParallaxLayer offset={0} speed={0.2} factor={0.5}>
                      <StyledRightGutter>
                        <img src={RightGutterSvg} />
                        <img src={RightGutterSvg} />
                      </StyledRightGutter>
                    </ParallaxLayer>
                  </StyledPanelContentRightSvg>
                </StyledPanelContentRight>
              </StyledPanelContentContainer>
            </StyledPanelContentBlock>
          </Parallax>
        </StyledPanelContent>
      </StyledPanelContainer>
    );
  }
}

export default Layout;
